import React, { useMemo, useState, useEffect } from "react"
import { Calendar, luxonLocalizer, Views } from "react-big-calendar"
import { DateTime } from "luxon"

// import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop"
import "react-big-calendar/lib/css/react-big-calendar.css"
import "./style.css"
import { ISOFormatToDate, addDays, log } from "./utils"
import CustomAgenda from "./customAgenda"
import WeeklyAgenda from "./weeklyAgenda"

// Luxon uses the Intl API, which currently does not contain `weekInfo`
// to determine which weekday is the start of the week by `culture`.
// The `luxonLocalizer` defaults this to Sunday, which differs from
// the Luxon default of Monday. The localizer requires this option
// to change the display, and the date math for determining the
// start of a week. Luxon uses non-zero based values for `weekday`.
const localizer = luxonLocalizer(DateTime, { firstDayOfWeek: 7 });


const FriendBadges = (props) => {
  const { friends, friendLookup } = props
  const badges = []
  for (const friend of friends) {
    const friendBadge = friendLookup[friend.friendId.toString()]
    if (friendBadge) {
      let badgeColor = 'lightgray'
      if (friend.registrationStatus === 'registered') {
        badgeColor = friendBadge.badge_color
      } else if (friend.registrationStatus === 'waitlisted') {
        badgeColor = 'goldenrod'
      }
      badges.push(
        <div key={friend.friendId} className="badge" style={{ backgroundColor: badgeColor }}>{friendBadge.badge_label}</div>
      )
    }
  }
  return badges
}

// strip the weekends by creating an identical event for each week range
function applyMTWRF(event) {
  const newEvents = []

  // get the actual start and end dates
  let startDate = ISOFormatToDate(event.start_date)
  let startDayOfWeek = startDate.getDay()
  if (startDayOfWeek === 0) {  // Sunday
    startDate = addDays(startDate, 1)
    startDayOfWeek = 1  // Monday
  } else if (startDayOfWeek === 6) {  // Saturday
    startDate = addDays(startDate, 2)
    startDayOfWeek = 1  // Monday
  }

  let endDate = ISOFormatToDate(event.end_date)
  const endDayOfWeek = endDate.getDay()
  if (endDayOfWeek === 0) {  // Sunday
    endDate = addDays(endDate, -2)
  } else if (endDayOfWeek === 6) {  // Saturday
    endDate = addDays(endDate, -1)
  }

  let currDate = startDate
  let currStartDayOfWeek = startDayOfWeek

  let cnt = 1
  while (currDate < endDate) {
    newEvents.push({
      ...event,
      "id": event.id + (cnt * 10000),
      "start": new Date(currDate),
      "end": addDays(currDate, 6 - currStartDayOfWeek)
    })
    currDate = addDays(currDate, 8 - currStartDayOfWeek)
    currStartDayOfWeek =  1  // default to Monday after initial start
    cnt += 1
  }
  return newEvents
}

function applySchedules(fullEvents) {
  let parsedEvents = []
  for (const event of fullEvents) {
    // for now, only deal with MTWRF schedules
    if (event.schedule_id != 1) {
      parsedEvents.push(event)
    } else {
      const newEvents = applyMTWRF(event)
      parsedEvents = parsedEvents.concat(newEvents)
    }
  }
  return parsedEvents
}

const MyCalendar = (props) => {
  const { showCalendarSchedules, programFriends, handleSelectEvent, friendLookup } = props
  const [selectedDate, setSelectedDate] = useState(0)

  // const { views } = useMemo(() => ({
  //   views: [
  //     Views.MONTH,
  //     Views.WEEK,
  //     Views.AGENDA,
  //   ],
  // }), [])

  const { views } = useMemo(() => ({
    views: {
      month: true,
      week: true,
      agenda: WeeklyAgenda,
    },
  }), [])

  const events = useMemo(() => {
    const fullEvents = showCalendarSchedules.map(cs => {
      return {
        ...cs,
        "friends": programFriends[cs.camp_program_id]
      }
    })
    /*
        {
        "id": 3,
        "camp_attendee_id": 1,
        "child_id": 3,
        "camp_id": 1,
        "camp_program_id": 1,
        "title": "4ft and Up Kitchen: Summer 2024",
        "campName": "4ft and Up Kitchen",
        "campProgramName": "Summer 2024",
        "programStart": "2024-06-17",
        "programEnd": "2024-08-23",
        "schedule_id": 1,
        "schedule": "MTWRF",
        "start_date": "2024-06-17",
        "end_date": "2024-08-23",
        "start": "2024-06-17T00:00:00.000Z",
        "end": "2024-08-23T00:00:00.000Z",
        "details": "",
        "friends": [
            {
                "friendId": 1,
                "start_date": "2024-06-17",
                "end_date": "2024-08-23",
                "registrationStatus": "registered"
            },
            {
                "friendId": 4,
                "start_date": "2024-06-17",
                "end_date": "2024-08-23",
                "registrationStatus": "waitlisted"
            }
        ],
        "registrationStatus": "none"
    },
    */
    const parsedEvents = applySchedules(fullEvents)
    return parsedEvents
  }, [showCalendarSchedules, programFriends])

  /*
  events = [{
    id: i,
    camp_attendee_id: program.camp_attendee_id,
    child_id: currentChildId,
    camp_id: program.camp_id,
    camp_program_id: program.camp_program_id,
    title: program.camp_program_name ? program.camp_name + ": " + program.camp_program_name : program.camp_name,
    campName: program.camp_name,
    campProgramName: program.camp_program_name,
    programStart: program.program_start,
    programEnd: program.program_end,
    schedule_id: program.schedule_id,
    schedule: program.schedule,
    fullProgram: program.full_program,
    start_date: program.start_date,
    end_date: program.end_date,
    start: new Date(program.start_date),
    end: new Date(program.end_date),
    allDay: true,
    details: "",
    friends: [],  // always empty and populated inside Calendar
    registrationStatus: program.registration_status,
  }]
  */

  // start by setting the calendar's start date to the first start date of any events... or the current date if none.
  useEffect(() => {
    const firstDate = events.reduce((prev, curr) => prev.start_date < curr.start_date ? prev : curr, 0)?.start_date ?? 0
    const firstDateDate = firstDate === 0 ? new Date() : ISOFormatToDate(firstDate)
    setSelectedDate(firstDateDate)
  }, [events])

  const eventPropGetter = (event, start, end, isSelected) => {
    // will be "none" if only friends are registered - show green in that case
    let backgroundColor = 'green'  // friends-only registered/interested/waitlisted
    if (event.registrationStatus === 'registered') {
      backgroundColor = 'blue'
    } else if (event.registrationStatus === 'waitlisted') {
      backgroundColor = 'DarkSeaGreen'
    } else if (event.registrationStatus === 'interested') {
      backgroundColor = 'CornflowerBlue'
    }
    return { style: { backgroundColor }}
  }
  const programTitle = (event) => {
    return (
      <div>
        {event.title}
        <FriendBadges friends={event.friends ?? []} friendLookup={friendLookup} />
      </div>
    )
  }

  // const Event = ({ event }) => {
  //   return (
  //     <span>
  //       <strong>{event.title}</strong>
  //       {event.desc && ':  ' + event.desc}
  //     </span>
  //   )
  // }

  // const EventAgenda = ({ event }) => {
  //   return (
  //     <span>
  //       <em style={{ color: 'magenta' }}>{event.title}</em>
  //       <p>{event.desc}</p>
  //     </span>
  //   )
  // }

  // const components = useMemo(() => ({
  //   agenda: {
  //     event: EventAgenda,
  //   },
  //   event: Event,
  // }), [EventAgenda])

  return (
    <div id="calendar">
      <Calendar
        localizer={localizer}
        // components={components}
        defaultView="agenda"
        date={selectedDate}
        onNavigate={date => { setSelectedDate(date) }}
        min={new Date()}
        onSelectEvent={handleSelectEvent}
        events={events}
        startAccessor="start"
        endAccessor="end"
        views={views}
        eventPropGetter={eventPropGetter}
        titleAccessor={programTitle}
        popup
      />
    </div>
  )
}

export default MyCalendar
