import React, { useEffect, useMemo, useState } from "react"
import { useAtom } from "jotai"
import { schoolsAtom, userStore, cacheFriendCalendarsAtom } from "./store"
import { JSON_FETCH_HEADERS } from './constants'
import { addDays,ISOFormatToDate, displayDateRange, formatISODate } from "./utils"
import { Row, Col, Form, Button, Accordion } from "react-bootstrap"

// strip the weekends by creating an identical event for each week range
function applyMTWRF(event) {
    const newEvents = []
  
    // get the actual start and end dates
    let startDate = ISOFormatToDate(event.start_date)
    let startDayOfWeek = startDate.getDay()
    if (startDayOfWeek === 0) {  // Sunday
      startDate = addDays(startDate, 1)
      startDayOfWeek = 1  // Monday
    } else if (startDayOfWeek === 6) {  // Saturday
      startDate = addDays(startDate, 2)
      startDayOfWeek = 1  // Monday
    }
  
    let endDate = ISOFormatToDate(event.end_date)
    const endDayOfWeek = endDate.getDay()
    if (endDayOfWeek === 0) {  // Sunday
      endDate = addDays(endDate, -2)
    } else if (endDayOfWeek === 6) {  // Saturday
      endDate = addDays(endDate, -1)
    }
  
    let currDate = startDate
    let currStartDayOfWeek = startDayOfWeek
  
    let cnt = 1
    while (currDate < endDate) {
      newEvents.push({
        ...event,
        "id": event.id + (cnt * 10000),
        "start": new Date(currDate),
        "end": addDays(currDate, 6 - currStartDayOfWeek)
      })
      currDate = addDays(currDate, 8 - currStartDayOfWeek)
      currStartDayOfWeek =  1  // default to Monday after initial start
      cnt += 1
    }
    return newEvents
}

// reshape events to be unique entries for M-F for length of total event
function applySchedules(fullEvents) {
    let parsedEvents = []
    for (const event of fullEvents) {
      // for now, only deal with MTWRF schedules
      if (event.schedule_id != 1) {
        parsedEvents.push(event)
      } else {
        const newEvents = applyMTWRF(event)
        parsedEvents = parsedEvents.concat(newEvents)
      }
    }
    return parsedEvents
}

function makeWeeks(startDate, endDate) {
    let currDate = startDate
    const weeks = []
    console.log(startDate, endDate)
    while (currDate < endDate) {
        weeks.push(currDate)
        currDate = addDays(currDate, 7)
    }
    return weeks
}

const WeekLabel = (props) => {
    const { monday } = props
    const friday = addDays(monday, 4)
    return (
        <>{displayDateRange(formatISODate(monday), formatISODate(friday), true)}</>
    )
}

const EventsForWeek =(props) => {
    const { weekStart, events } = props
    const weekEnd = addDays(weekStart, 7)
    const weekEvents = events.filter(event => event.start >= weekStart && event.start < weekEnd)
    return (
        <ul>
            {weekEvents.map(event => (
                <li className="li-padding" key={event.id}>
                    {event.title}
                </li>
            ))}
        </ul>
    )
}

const WeekEvents = (props) => {
    const { weekStart, events } = props
    return (
        <>
        <Accordion eventkey={formatISODate(weekStart)}>
            <Accordion.Header>
                <div className="friend-cal">
                    <div className="friend-cal-name">
                        <WeekLabel monday={weekStart} />
                    </div>
                </div>
            </Accordion.Header>
            <Accordion.Body>
                <EventsForWeek weekStart={weekStart} events={events} />
            </Accordion.Body>
        </Accordion>
        </>
    )
}

const WeeklyOverview = (props) => {
    const { showCalendarSchedules, programFriends, handleSelectEvent, friendLookup } = props
    const [userLoggedIn, setUserLoggedIn] = useAtom(userStore)
    const today = new Date()
    const dayOfWeek = today.getDay()
    const [startOfWeek, setStartOfWeek] = useState(addDays(today, -(dayOfWeek-1)))

    const events = useMemo(() => {
        const fullEvents = showCalendarSchedules.map(cs => {
          return {
            ...cs,
            "friends": programFriends[cs.camp_program_id]
          }
        })
        /*
            {
            "id": 3,
            "camp_attendee_id": 1,
            "child_id": 3,
            "camp_id": 1,
            "camp_program_id": 1,
            "title": "4ft and Up Kitchen: Summer 2024",
            "campName": "4ft and Up Kitchen",
            "campProgramName": "Summer 2024",
            "programStart": "2024-06-17",
            "programEnd": "2024-08-23",
            "schedule_id": 1,
            "schedule": "MTWRF",
            "start_date": "2024-06-17",
            "end_date": "2024-08-23",
            "start": "2024-06-17T00:00:00.000Z",
            "end": "2024-08-23T00:00:00.000Z",
            "details": "",
            "friends": [
                {
                    "friendId": 1,
                    "start_date": "2024-06-17",
                    "end_date": "2024-08-23",
                    "registrationStatus": "registered"
                },
                {
                    "friendId": 4,
                    "start_date": "2024-06-17",
                    "end_date": "2024-08-23",
                    "registrationStatus": "waitlisted"
                }
            ],
            "registrationStatus": "none"
        },
        */
        const parsedEvents = applySchedules(fullEvents)
        return parsedEvents
    }, [showCalendarSchedules, programFriends])
    /*
        events = [{
            id: i,
            camp_attendee_id: program.camp_attendee_id,
            child_id: currentChildId,
            camp_id: program.camp_id,
            camp_program_id: program.camp_program_id,
            title: program.camp_program_name ? program.camp_name + ": " + program.camp_program_name : program.camp_name,
            campName: program.camp_name,
            campProgramName: program.camp_program_name,
            programStart: program.program_start,
            programEnd: program.program_end,
            schedule_id: program.schedule_id,
            schedule: program.schedule,
            fullProgram: program.full_program,
            start_date: program.start_date,
            end_date: program.end_date,
            start: new Date(program.start_date),
            end: new Date(program.end_date),
            allDay: true,
            details: "",
            friends: [],  // always empty and populated inside Calendar
            registrationStatus: program.registration_status,
        }]
    */

    // start by setting the calendar's start date to the first start date of any events... or the current date if none.
    // useEffect(() => {
    //     const today = new Date()
    //     const todayISO = formatISODate(today)
    //     const firstDate = events.reduce((prev, curr) => prev.start_date < curr.start_date ? prev : curr, 0)?.start_date ?? 0

    //     const dayOfWeek = today.getDay()
    //     // monday is 1, we want to nudge the first day to the Monday of the current week
    //     const firstWeekStart = addDays(today, -(dayOfWeek-1))            
    //     setStartOfWeek(firstWeekStart)
    //     // const firstDateDate = firstDate === 0 || firstDate < todayISO ? today : ISOFormatToDate(firstDate)
    //     // setSelectedDate(firstDateDate)
    // }, [events])

    let currStartOfWeek = events && events.length > 0 ? events[0].start : startOfWeek
    let endOfEvents = events && events.length > 0 ? events[events.length-1].end : startOfWeek
    const weeks  = makeWeeks(currStartOfWeek, new Date())
    return (
        <div id="main" className="px-4 pt-3">
            <div className="pt-3">
                <h1>Weekly Schedule</h1>
                {weeks.map((week) => <WeekEvents weekStart={week} events={events} />)}
            </div>
        </div>
    )
}

export default WeeklyOverview